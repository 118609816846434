export const EXPERIOR_PROMPTS_HOME = [
  {
    title: "Email Marketing",
    id: "email-marketing",
    prompts: [
      {
        title:
          "Utilizing the 'Attention-Interest-Desire-Action' model, design an email marketing campaign that showcases my insurance services and specialized policies, targeting my ideal client.",
        id: "follow-up-email",
        shortTitle: "Attention-Interest-Desire-Action",
      },
      {
        title:
          'Craft five compelling subject lines for an introductory email where i delineate my insurance expertise and distinct policy benefits to [a potential client segment, e.g., "recent car buyers"]',
        shortTitle: "Email Subject Generator",
        id: "email-subject-generator",
      },
      {
        title:
          "What are the current trends in the insurance sector? Compile content for a newsletter that resonates with [your ideal clientele's primary insurance concerns and needs]",
        shortTitle: "Newsletter Inspiration",
        id: "newsletter-inspiration",
      },
      {
        title:
          "Leveraging the 'Pain-Agitate-Solution' method, draft an email underlining the challenges of managing potential risks and how your insurance solutions provide peace of mind tailored to [your ideal clientele's situations, e.g., 'asset protection' or 'healthcare coverage']",
        shortTitle: "Pain-Agitate-Solution",
        id: "Pain-Agitate-Solution",
      },
    ],
  },
  {
    title: "Blogging",
    id: "blogging",
    prompts: [
      {
        title:
          "Pen an enlightening article on [an applicable insurance topic, e.g., 'the advantages of whole life insurance']. Delve into [specific features or benefits, e.g., 'how cash value accumulation works']",
        shortTitle: "Blog Post",
        id: "blog-post",
      },
      {
        title:
          "Construct an outline for a blog focusing on [a relevant insurance subject, e.g., 'travel insurance essentials']. Optionally, emphasize the significance of [a particular coverage or aspect, e.g., 'medical evacuation benefits']",
        shortTitle: "Blog Outline",
        id: "blog-outline",
      },
      {
        title:
          "Illustrate a success narrative where you guided [a specific client or persona, e.g., 'a traveling retiree'] through [a particular insurance journey, e.g., 'claim process after a trip mishap']. Embed authentic testimonials, significant milestones achieved, and any relevant visuals.",
        shortTitle: "Customer Case Study",
        id: "customer-case-study",
      },
    ],
  },
  {
    title: "Social Media",
    id: "social-media",
    prompts: [
      {
        title:
          "Formulate a captivating caption for [your post's insurance theme, e.g., 'benefits of health insurance']. Engage your followers by querying their experiences or viewpoints, weave in pertinent emojis, and conclude with up to three relevant hashtags like #RiskFreeLiving or #CoverageCounts",
        shortTitle: "Caption generator",
        id: "caption-generator",
      },
      {
        title:
          'Strategize a content calendar revolving around [your insurance products, industry insights, and emerging trends]. Prioritize the interests and insurance needs of [your ideal clientele, e.g., "homeowners"]',
        shortTitle: "Content Calendar",
        id: "content-calender-social-media",
      },
      {
        title:
          "Compose an engaging LinkedIn post delving into [a specific insurance concept or update, e.g., 'the impact of recent regulatory changes on insurance premiums']. Furnish it with:\nBullet points detailing the implications or advantages.\nA mix of concise summaries and detailed examinations. \n A tone that's both authoritative and approachable.",
        shortTitle: "LinkedIn Post",
        id: "linkedin-post-social-media",
      },
    ],
  },
  {
    title: "Strategy",
    id: "strategy",
    prompts: [
      {
        title:
          "Dissect [my marketing approach for new policyholders]. Dive deep into the strategy's multiple dimensions, challenging traditional insurance marketing paradigms.",
        shortTitle: "Refraining Insurance Perspectives",
        id: "refraining-insurance-perspectives",
      },
      {
        title:
          "Applying behavioral economics, probe into [my client acquisition tactics]. Explore how cognitive biases, emotional responses, and societal factors might influence a client's decision to opt for our insurance solutions",
        shortTitle: "Behavioral Economics Principles",
        id: "behavioral-economics-principles",
      },
      {
        title:
          "Utilize the Blue Ocean Strategy to assess [your insurance offering or method]. Focus on crafting unique services in the insurance sector, avoiding traditional market competitions.",
        shortTitle: "Blue Ocean Strategy",
        id: "blue-ocean",
      },
      {
        title:
          "Implement the Brand Ecosystem Development concept when evaluating [your digital or client engagement strategy]. Think about cohesive brand experiences spanning various touch points.",
        shortTitle: "Brand Ecosystem Development",
        id: "brand-Ecosystem-development",
      },
      {
        shortTitle: "Innovation Ambition Matrix",
        id: "Innovation-Ambition-Matrix",
        title:
          "Examine [your insurance product lineup or introduction strategy] with the Innovation Ambition Matrix. Strategize to mix foundational, evolutionary, and revolutionary insurance products.",
      },
      {
        shortTitle: "Lean Startup Principles",
        id: "Lean-Startup-Principles",
        title:
          "Apply Lean Startup Principles to fine-tune [your new insurance advisory service or digital tool]. Seek early feedback and iterate.",
      },
      {
        shortTitle: "Client Persona Building",
        id: "Client-Persona-Building",
        title:
          "Use Client Persona Building to enhance [your client approach or communication]. Develop detailed profiles highlighting clients' risk factors, lifestyle, and financial capacities.",
      },
      {
        shortTitle: "Hyper-Personalization Strategy",
        id: "Hyper-Personalization-Strategy",
        title:
          "Use Hyper-Personalization to elevate [your policy recommendation or client engagement]. Leverage data insights for pinpoint insurance solutions.",
      },
      {
        shortTitle: "Jobs to Be Done Framework",
        id: "Jobs-to-Be-Done-Framework",
        title:
          "Review [your advisory approach or digital tool] using the Jobs to Be Done Framework. Identify core reasons clients seek insurance advice.",
      },
      {
        shortTitle: "Client Behavior Analysis",
        id: "Client-Behavior-Analysis",
        title:
          "Deploy Client Behavior Analysis to evaluate [your policy recommendation method]. Investigate factors influencing clients' insurance decisions.",
      },
      {
        shortTitle: "Kano Model Analysis",
        id: "Kano-Model-Analysis",
        title:
          "Evaluate [your core insurance products or services] through Kano Model Analysis. Categorize offerings into essential, performance, and delight categories.",
      },
      {
        shortTitle: "Referral Strategy",
        id: "Referral-Strategy",
        title:
          "Assess [your referral program or client acquisition method] using the Viral Loop Strategy. Foster organic referrals through satisfied clients.",
      },
      {
        shortTitle: "Value Chain Analysis",
        id: "Value-Chain-Analysis",
        title:
          "Apply Value Chain Analysis on [your insurance advisory process]. Analyze every phase, from initial touchpoints to claim support, finding areas of distinction.",
      },
      {
        shortTitle: "Client Retention Strategy",
        id: "Client-Retention-Strategy",
        title:
          "Use the Five Whys Technique to investigate [your client retention or renewal strategy]. Dive deep into reasons for client churn.",
      },
      {
        shortTitle: "Client Lifetime Value (CLV)",
        id: "Client-Lifetime-Value-CLV",
        title:
          "Gauge [your client approach or retention methods] with CLV in mind. Balance acquisition costs with renewal opportunities.",
      },
      {
        shortTitle: "Client Communications",
        id: "Client-Communications",
        title:
          "Examine [your communication methods or tools] emphasizing Emotional Intelligence. Ensure clarity and empathy, especially during claim discussions.",
      },
      {
        shortTitle: "Disruptive Innovation",
        id: "Disruptive-Innovation",
        title:
          "View [your digital offering or insurance product lineup] through the Disruptive Innovation lens. Stay alert to innovations transforming insurance.",
      },
      {
        shortTitle: "Network Effects",
        id: "Network-Effects",
        title:
          "Evaluate [your digital platform or community initiative] using the Network Effects concept. Consider how value amplifies with more user engagement.",
      },
      {
        shortTitle: "Double Loop Learning",
        id: "Double-Loop-Learning",
        title:
          "Incorporate Double Loop Learning when reflecting on [your advisory methodology]. Continuously challenge and evolve your beliefs and approaches.",
      },
      {
        shortTitle: "Long Tail Strategy",
        id: "Long-Tail-Strategy",
        title:
          "Scrutinize [your insurance product lineup] with the Long Tail Strategy. Delve into niche products catering to specific needs.",
      },
      {
        shortTitle: "Pygmalion Effect",
        id: "Pygmalion-Effect",
        title:
          "Use the Pygmalion Effect to evaluate [your communication or expectation setting methods]. Understand how your conveyed beliefs shape client actions.",
      },
      {
        shortTitle: "Prospect Theory",
        id: "Prospect-Theory",
        title:
          "Apply Prospect Theory when assessing [your risk communication or product recommendation]. Consider how clients balance perceived risks against insurance costs.",
      },
      {
        shortTitle: "OODA Loop",
        id: "OODA-Loop",
        title:
          "Implement the OODA Loop to refine [your market adaptation or product lineup strategy]. Stay agile by quickly observing, orienting, deciding, and acting in the insurance space.",
      },
      {
        shortTitle: "Scenario Planning",
        id: "Scenario-Planning",
        title:
          "Incorporate Scenario Planning for [your product lineup or advisory approach]. Anticipate varied market events, from regulatory shifts to economic changes, ensuring robust, future-ready advice.",
      },
    ],
  },
];

export const EXPERIOR_PROMPTS = [
  {
    title: "FiN",
    id: "fin",
    prompts: [
      {
        title: "Write me a follow-up email for this FiN prospect",
        id: "follow-up-email",
        shortTitle: "Follow-up email",
      },
      {
        title: "Write me a Linkedin post to promote my FiN tool",
        shortTitle: "Linkedin post",
        id: "linkedin-post",
      },
      {
        title: "Write me a Facebook  post to promote my FiN tool",
        shortTitle: "Facebook post",
        id: "facebook-post",
      },
      {
        title: "How do I recruit new agents with my FiN tool?",
        shortTitle: "Recruit agents",
        id: "recruit-agents",
      },
    ],
  },
  {
    title: "IPN",
    id: "ipn",
    prompts: [
      {
        title: "Write me a follow-up email for this IPN prospect",
        shortTitle: "Follow-up email",
        id: "follow-up-email-ipn",
      },
      {
        title: "Write me a Linkedin post to promote my IPN tool",
        shortTitle: "Linkedin post",
        id: "linkedin-post-ipn",
      },
      {
        title: "Write me a Facebook  post to promote my IPN",
        shortTitle: "Facebook post",
        id: "facebook-post-ipn",
      },
      {
        title:
          "Write me a sales script to sell more term life policies with my IPN\n",
        shortTitle: "Sales Script Term Life",
        id: "term-life-sales-plan",
      },
      {
        title: "Write me a sales plan to sell more annuities with my IPN",
        shortTitle: "Sales Script Annuities",
        id: "annuity-sales-plan",
      },
    ],
  },
  {
    title: "Video Magnet",
    id: "video-magnet",
    prompts: [
      {
        title:
          "Write me a post to talk about the Experior business opportunity",
        shortTitle: "Experior business opportunity",
        id: "experior-business-opportunity",
      },
      {
        title:
          "Write a recruiting plan to recruit more agents from Linkedin to Experior",
        shortTitle: "Recruit agents",
        id: "recruit-agents-linkedin",
      },
      {
        title: "What is Experior's unique value proposition?",
        shortTitle: "Experior's unique value proposition",
        id: "experior-unique-value-proposition",
      },
      {
        title: "Write me a LinkedIn job post to recruit new agents",
        shortTitle: "Linkedin job post",
        id: "linkedin-job-post",
      },
    ],
  },
  {
    title: "Digital Business Card",
    id: "digital-business-card",
    prompts: [
      {
        title: "Write me a short text script to share my digital business card",
        shortTitle: "Short text script",
        id: "share-digital-business-card",
      },
      {
        title:
          "Write me a content marketing plan to promote my digital business card",
        shortTitle: "Content marketing plan",
        id: "content-marketing-plan",
      },
      {
        title: "How can I get more views on my digital business card?",
        shortTitle: "Get more views",
        id: "get-more-views",
      },
      {
        title:
          "How do I share my digital business card with friends and family?",
        shortTitle: "Share with friends and family",
        id: "share-with-friends-and-family",
      },
    ],
  },
];

export const DEFAULT_PROMPTS_HOME = [
  {
    title: "Email Marketing",
    id: "email-marketing",
    prompts: [
      {
        shortTitle: "Attention-Interest-Desire-Action",
        id: "Attention-Interest-Desire-Action",
        title:
          "Craft an email marketing campaign using the 'Attention-Interest-Desire-Action' framework. Highlight your financial advisory services and unique strategies, targeting your ideal clientele, such as 'young professionals' or 'retirees.'",
      },
      {
        shortTitle: "Email Subject Generator",
        id: "Email-Subject-Generator",
        title:
          "Generate five captivating subject lines for an introduction email. In this email, present your financial advisory expertise and unique value propositions to a potential client segment, for example, 'new homeowners.'",
      },
      {
        shortTitle: "Newsletter Inspiration",
        id: "Newsletter-Inspiration",
        title:
          "Curate content for a newsletter that addresses the prevailing trends in the financial advisory landscape. Tailor the content to focus on your ideal clientele's primary financial concerns and aspirations.",
      },
      {
        shortTitle: "Pain-Agitate-Solution",
        id: "Pain-Agitate-Solution",
        title:
          "Draft an email using the 'Pain-Agitate-Solution' approach. Illustrate the challenges of navigating financial landscapes and how your advisory services offer solutions tailored to your ideal clientele's goals, such as 'wealth accumulation' or 'risk mitigation.'",
      },
    ],
  },
  {
    id: "Blogging",
    title: "Blogging",
    prompts: [
      {
        shortTitle: "Blog Post",
        id: "Blog-Post",
        title:
          "Compose an insightful article on a relevant financial topic, such as 'tax-efficient investing.' Delve into specific aspects or benefits, like 'how tax harvesting can maximize returns.'",
      },
      {
        shortTitle: "Blog Outline",
        id: "Blog-Outline",
        title:
          "Sketch an outline for a piece centered on a pertinent financial topic, for example, 'retirement planning.' Optionally, stress the importance of a specific strategy or approach, like 'diversified asset allocation.'",
      },
      {
        shortTitle: "Customer Case Study",
        id: "Customer-Case-Study",
        title:
          "Showcase a success story where you guided a specific client or persona, such as 'a young family,' through a particular financial journey, like 'buying their first home.' Embed genuine testimonials, highlight significant financial milestones reached, and include any relevant visuals.",
      },
    ],
  },
  {
    id: "Social-Media",
    title: "Social Media",
    prompts: [
      {
        shortTitle: "Caption Generator",
        id: "Caption-Generator",
        title:
          "Create a captivating caption for a post on your chosen financial theme, such as 'mutual funds vs. ETFs.' Engage your audience by asking questions about their experiences or opinions, incorporate fitting emojis, and include up to three pertinent hashtags like #WealthBuilding or #FinancialForesight.",
      },
      {
        shortTitle: "Content Calendar",
        id: "Content-Calendar",
        title:
          "Develop a content calendar focused on your advisory services, unique financial insights, and current market trends. Ensure that the interests and financial goals of your ideal clientele, for example, 'entrepreneurs,' are at the forefront of your content strategy.",
      },
      {
        shortTitle: "LinkedIn Post",
        id: "LinkedIn-Post",
        title:
          "Craft a dynamic LinkedIn post that provides details on a specific financial concept or update, such as 'the implications of the latest tax reforms.' Include bullet points outlining the impacts or benefits, a mix of succinct takeaways and in-depth insights, and maintain a tone that is both authoritative and relatable.",
      },
    ],
  },
  {
    id: "Strategy",
    title: "Strategy",
    prompts: [
      {
        shortTitle: "Reframing Marketing Perspectives",
        id: "Reframing-Marketing-Perspectives",
        title:
          "Analyze my marketing strategy for acquiring new clients. Delve deep into the various facets of the strategy, challenging conventional financial marketing wisdom.",
      },
      {
        shortTitle: "Behavioral Economics Principles",
        id: "Behavioral-Economics-Principles",
        title:
          "Using principles from behavioral economics, analyze my client acquisition strategy. Examine how cognitive biases, emotional triggers, and social influences might impact a client's choice to engage with our financial advisory services.",
      },
      {
        shortTitle: "Blue Ocean Strategy",
        id: "Blue-Ocean-Strategy",
        title:
          "Utilize the Blue Ocean Strategy framework to assess my client outreach approach. Concentrate on identifying and developing unique niches in the financial advisory sector, rather than vying for the same client base as other advisors.",
      },
      {
        shortTitle: "Brand Ecosystem Development",
        id: "Brand-Ecosystem-Development",
        title:
          "Employ the concept of Brand Ecosystem Development to evaluate my client engagement strategy.",
      },
      {
        shortTitle: "Innovation Ambition Matrix",
        id: "Innovation-Ambition-Matrix",
        title:
          "Evaluate my client acquisition strategy using the Innovation Ambition Matrix. Chart initiatives within the matrix to ensure a mix of enhancing our core services.",
      },
      {
        shortTitle: "Lean Startup Principles",
        id: "Lean-Startup-Principles",
        title:
          "Implement Lean Startup Principles to evaluate my client service offering.",
      },
      {
        shortTitle: "Client Persona Building",
        id: "Client-Persona-Building",
        title:
          "Apply the technique of Customer Persona Building to assess my client engagement strategy. Outline distinct client archetypes, detailing their financial habits, goals, challenges, and preferences to better tailor our advisory services.",
      },
      {
        shortTitle: "Hyper-Personalization Strategy",
        id: "Hyper-Personalization-Strategy",
        title:
          "Employ the Hyper-Personalization Strategy to assess my client relationship approach. Utilize data analytics and AI-driven insights to offer an exceptionally tailored financial advisory experience for each individual client.",
      },
      {
        shortTitle: "Jobs to Be Done Framework",
        id: "Jobs-to-Be-Done-Framework",
        title:
          "Review my financial advisory strategy using the Jobs to Be Done Framework. Concentrate on understanding the financial challenges and objectives clients aim to address when seeking our services.",
      },
      {
        shortTitle: "Client Behavior Analysis",
        id: "Client-Behavior-Analysis",
        title:
          "Apply Client Behavior Analysis to assess my portfolio recommendation strategy. Delve deep into the psychological drivers, personal factors, and social influences that guide clients' investment and financial decision-making.",
      },
      {
        shortTitle: "Kano Model Analysis",
        id: "Kano-Model-Analysis",
        title:
          "Assess my core offerings utilizing the Kano Model Analysis. Classify clients' financial needs and preferences into foundational requirements, performance-based desires, and elements that can delight and exceed expectations.",
      },
      {
        shortTitle: "Referral Strategy",
        id: "Referral-Strategy",
        title:
          "Examine my client referral strategy using the Viral Loop Strategy. Design a mechanism where satisfied clients naturally encourage peers and acquaintances to seek our financial advisory services.",
      },
      {
        shortTitle: "Value Chain Analysis",
        id: "Value-Chain-Analysis",
        title:
          "Use Value Chain Analysis to assess my financial advisory service delivery. Scrutinize every activity involved in our service provision, from initial client contact to ongoing management, identifying where we add unique value and potential areas for establishing a competitive edge.",
      },
      {
        shortTitle: "Client Retention Strategy",
        id: "Client-Retention-Strategy",
        title:
          "Apply the Five Whys Technique to dissect my client retention strategy. Continually ask 'why?' to delve deeper into any issues or challenges, aiming to uncover the fundamental causes behind client dissatisfaction.",
      },
      {
        shortTitle: "Client Lifetime Value",
        id: "Client-Lifetime-Value",
        title:
          "Evaluate my client engagement approach by taking into account the Client Lifetime Value (CLV). Gauge the long-term value and potential of each client relationship, ensuring that our acquisition, retention, and monetization efforts are harmonized and optimized.",
      },
      {
        shortTitle: "Client Communications",
        id: "Client-Communications",
        title:
          "Assess my client communication strategy, keeping Emotional Intelligence at the forefront. Acknowledge and navigate both your emotions and those of your clients to ensure more compassionate and impactful financial advising.",
      },
      {
        shortTitle: "Disruptive Innovation",
        id: "Disruptive-Innovation",
        title:
          "Examine my investment strategy or service offering using the Disruptive Innovation lens. Reflect on how radical shifts in financial technology, investment methodologies, or other game-changing developments could reshape the advisory landscape and the expectations of our clients.",
      },
      {
        shortTitle: "Network Effects",
        id: "Network-Effects",
        title:
          "Evaluate my digital platform or client community initiative by leveraging the concept of Network Effects. Contemplate how the worth and appeal of this offering might grow as an increasing number of clients or stakeholders engage with it.",
      },
      {
        shortTitle: "Double Loop Learning",
        id: "Double-Loop-Learning",
        title:
          "Apply Double Loop Learning to assess my client advisory methodology. Rather than solely focusing on problem-solving, delve deeper into the foundational assumptions and beliefs driving our approach, promoting a more adaptive and self-aware advisory process.",
      },
      {
        shortTitle: "Long Tail Strategy",
        id: "Long-Tail-Strategy",
        title:
          "Examine my asset allocation or financial product offerings with the Long Tail Strategy in mind. Reflect on how specialized investment opportunities or niche financial products might play a significant role in diversifying and strengthening overall portfolio performance.",
      },
      {
        shortTitle: "Pygmalion Effect",
        id: "Pygmalion-Effect",
        title:
          "Utilize the Pygmalion Effect to evaluate my client communication and expectation setting process. Acknowledge how the expectations we set, or the beliefs we convey, can significantly shape client behaviors, investment outlook, and overall financial results.",
      },
      {
        shortTitle: "Prospect Theory",
        id: "Prospect-Theory",
        title:
          "Employ Prospect Theory to evaluate my investment advice or risk communication strategy. Grasp how clients perceive potential gains versus losses and the implications this has on their financial choices and reactions to market dynamics.",
      },
      {
        shortTitle: "OODA Loop",
        id: "OODA-Loop",
        title:
          "Implement the OODA Loop methodology to assess my market adaptation strategy. Progressively cycle through observing market trends, orienting to client needs, deciding on the best course of action, and executing those decisions, iterating as market conditions and client requirements evolve.",
      },
      {
        shortTitle: "Scenario Planning",
        id: "Scenario-Planning",
        title:
          "Utilize Scenario Planning in evaluating my investment strategy or portfolio design. Craft diverse future financial scenarios, ranging from market booms to downturns, and assess how the strategy fares in each, pinpointing both potential vulnerabilities and growth opportunities.",
      },
    ],
  },
];

export const DEFAULT_PROMPTS = [
  {
    title: "FiN",
    id: "fin",
    prompts: [
      {
        title: "Write me a follow-up email for this FiN prospect",
        shortTitle: "Follow-up email",
        id: "follow-up-email",
      },
      {
        title:
          "Write me questions I can ask this FiN prospect on my appointment",
        shortTitle: "Appointment questions",
        id: "questions",
      },
      {
        title: "Write me a budgeting template for this FiN prospect",
        shortTitle: "Budgeting template",
        id: "budgeting-template",
      },
      {
        title: "Write me a debt stacking plan for this FiN prospect",
        shortTitle: "Debt stacking plan",
        id: "debt-stacking-plan",
      },
      {
        title:
          "Write me a plan for an emergency savings fund for this FiN prospect",
        shortTitle: "Emergency savings fund",
        id: "emergency-savings-fund",
      },
      {
        title: "Write me a template for a living will for this FiN prospect",
        shortTitle: "Living will",
        id: "living-will",
      },
    ],
  },
];

export const welcomeSuggestions = [
  {
    text: "Employ the concept of Brand Ecosystem Development to evaluate my client engagement strategy.",
  },
  {
    text: "Evaluate my client acquisition strategy using the Innovation Ambition Matrix.",
  },
  {
    text: "Implement Lean Startup Principles to evaluate my client service offering.",
  },
  {
    text: "Compose an insightful article on a relevant financial topic, such as 'tax-efficient investing.'",
  },
];
