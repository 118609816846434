import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomButton } from "../components/CustomButton";
import CustomDropdown from "../components/CustomDropdown";
import CustomTextArea from "../components/CustomTextArea";
import Markdown from "../components/Markdown";

import { useDocumentReviewHandler } from "../logic/documentReview";
import { getDocumentStatus, uploadText } from "../services/fileUpload";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import "./Compliance.css";

const Compliance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageForReview, setMessageForReview] = useState("");
  const [useCase, setUseCase] = useState("ria");
  const [showUseCaseDropdown, setShowUseCaseDropdown] = useState(false);

  const { reviewDocument, progress, showProgress, resultData } =
    useDocumentReviewHandler(setIsLoading);

  // Get the message for review from redux
  const messageForReviewFromRedux = useSelector(
    (state) => state.compliance.messageForReview
  );

  // Get the api key and secret from session storage
  let data = sessionStorage.getItem("data");
  if (data === undefined || data === null) {
    data = null;
  } else {
    data = JSON.parse(data);
  }
  const apiKey = data !== null ? data["api_key"] : null;
  const apiSecret = data !== null ? data["api_secret"] : null;

  // This is the KB that we upload new documents to
  const kbID = "239aa451-cc85-447a-aa1d-4464d2f813a5";
  // const idToken = data["idToken"];
  const idToken = useAppSelector((state) => state.auth.idToken);

  // useEffect(() => {
  //   dispatch(logout());
  // }, [apiKey]);

  // const chatConfig = data !== null ? JSON.parse(data["chat_config"]) : {};
  const defaultKBs = [
    "49fb1557-dc03-49a7-9f31-cdaf059fb43b",
    "64be5a17-d2b0-4ca8-951a-eeced0a0b9d0",
    "7df1f6b3-7f22-419e-b1bf-faaad7acbe1f",
  ];
  const chatConfig = {
    knowledge_base_ids: defaultKBs,
  };

  async function pollForDocumentStatus(resData) {
    const authToken = btoa(
      unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
    );

    // Send the document for review
    const documentId = resData["id"];
    console.log("documentId", documentId);
    // Sleep for 1 second
    await new Promise((r) => setTimeout(r, 1000));
    // Get the document status
    let [newResData, newStatus] = await getDocumentStatus(
      idToken,
      kbID,
      documentId
    );
    console.log("newResData", newResData);

    let documentStatus = newResData["vectorization_status"];

    let pollCount = 0;
    while (documentStatus !== "COMPLETE" && pollCount < 120) {
      // Sleep for 1 second
      await new Promise((r) => setTimeout(r, 1000));
      [newResData, newStatus] = await getDocumentStatus(
        idToken,
        kbID,
        documentId
      );
      console.log("newResData", newResData);

      let newDocumentStatus = newResData["vectorization_status"];
      documentStatus = newDocumentStatus;
      pollCount += 1;
    }

    if (documentStatus === "COMPLETE") {
      // Start the document review now
      console.log("Starting document review");
      reviewDocument(kbID, documentId, useCase, idToken, authToken);
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  // async function handleDuplicateDocument(resData) {
  //   const documentId = resData.error["existing_document_id"];
  //   console.log("documentId", documentId);
  //   const authToken = btoa(
  //     unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
  //   );
  //   reviewDocument(kbID, documentId, useCase, idToken, authToken);
  // }

  async function startDocumentReview() {
    setIsLoading(true);
    //const kbID = chatConfig["knowledge_base_ids"][0]
    const [resData, status] = await uploadText(messageForReview, kbID, idToken);

    if (status === 200 && !resData.error) {
      pollForDocumentStatus(resData);
    } else if (resData.error) {

      if (
        resData.existing_document_id !== undefined &&
        resData.existing_document_id !== null
      ) {
        console.log("Document already exists");
        const documentId = resData.existing_document_id;
        console.log("documentId", documentId);
        const authToken = btoa(
          unescape(encodeURIComponent(`${apiKey}:${apiSecret}`))
        );
        reviewDocument(kbID, documentId, useCase, idToken, authToken);
      }
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (
      messageForReviewFromRedux !== null &&
      messageForReviewFromRedux !== undefined
    ) {
      setMessageForReview(messageForReviewFromRedux);
    }
  }, [messageForReviewFromRedux]);

  useEffect(() => {
    // Check the kb ids in the chat config
    if (chatConfig !== null && chatConfig !== undefined) {
      const kbIds = chatConfig["knowledge_base_ids"];
      if (kbIds.length > 1) {
        setShowUseCaseDropdown(true);
      } else {
        // Define the use case based on the kb id
        switch (kbIds[0]) {
          case "64be5a17-d2b0-4ca8-951a-eeced0a0b9d0":
            setUseCase("ria");
            break;
          case "49fb1557-dc03-49a7-9f31-cdaf059fb43b":
            setUseCase("finra");
            break;
          case "7df1f6b3-7f22-419e-b1bf-faaad7acbe1f":
            setUseCase("hybrid");
            break;
        }
      }
    }
  }, [chatConfig]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"compliance"} />
      <div className="compliance-review-section">
        {/* <Header darkMode={false} currentPage={"compliance"} /> */}
        <div className="compliance-review-container">
          {isLoading && <BouncingDots />}

          <div className="compliance-review-content-container">
            <p className="compliance-review-header-text">Compliance Review</p>

            {showUseCaseDropdown && (
              <div className="compliance-review-use-case-container">
                <p className="use-case-header-text">Use case:</p>
                <CustomDropdown
                  valueList={["ria", "finra", "hybrid"]}
                  textList={["RIA", "FINRA", "Hybrid"]}
                  value={useCase}
                  sendDataToParent={(val) => setUseCase(val)}
                  customStyle={{ width: "200px", maxHeight: "400px" }}
                />
              </div>
            )}

            <p className="message-for-review-header-text">Message to review:</p>

            <CustomTextArea
              text={messageForReview}
              sendDataToParent={(data) => setMessageForReview(data)}
              rows={3}
            />
            <div className="submit-review-button-container">
              <CustomButton
                onClick={() => startDocumentReview()}
                buttonText={"Submit Review"}
                disabled={messageForReview === "" || isLoading}
              />
            </div>
          </div>

          <div className="compliance-review-result-container">
            {resultData != null && (
              <div className="document-review-result-container">
                <div className="document-review-result-text">
                  <p className={`semi-bold-font-light`}>Final review</p>
                </div>
                <Markdown
                  markdownContent={resultData}
                  className={`regular-font-light document-review-result-text`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compliance;

/*
Upon reviewing the concatenated extracted information, several potential issues arise that may conflict with SEC marketing compliance rules and regulations for a Registered Investment Advisor (RIA). Below are the identified issues, explanations for why they are not compliant, and suggested solutions:

Claim of Consistently Delivered Results:

Non-Compliant Text: "The firm claims to have consistently delivered remarkable results with a 25% annualized return rate."
Reason for Non-Compliance: The SEC prohibits RIAs from making potentially misleading claims about investment performance. Claiming a consistent 25% annualized return rate could be seen as a guarantee of future performance, which is not permissible.
Suggested Solution: The firm should present past performance data in a way that is balanced and includes disclosures about the potential for loss and that past performance is not indicative of future results.
Client Testimonial:

Non-Compliant Text: "Client testimonial from Jane Smith praising the stability and growth of her portfolio."
Reason for Non-Compliance: The use of testimonials is restricted under the SEC's marketing rules. Testimonials can be considered endorsements, which can be misleading as they may imply that the experience of the testimonial giver is typical or can be expected by other clients.
Suggested Solution: The firm should remove client testimonials from their marketing materials or ensure that any testimonials used comply with the SEC's no-action letter regarding testimonials, which includes proper disclosures and context.
Firm's Commitment and Team's Expertise:

Text: "The firm's commitment to understanding and addressing individual financial goals" and "The team's expertise and deep understanding of market dynamics."
Reason for Compliance: These statements are generally compliant as they describe the service and approach of the firm without making specific performance claims or promises.
Suggested Solution: No action needed, but the firm should ensure that these statements are true and not misleading.
Invitation to Join the Firm:

Non-Compliant Text: "The invitation to join the firm and experience the difference their investment approach can make."
Reason for Non-Compliance: This statement could be interpreted as a guarantee of positive investment outcomes, which is not compliant with SEC regulations.
Suggested Solution: The firm should rephrase the invitation to clarify that they offer a unique investment approach without implying guaranteed results. For example, "Discover how our investment approach is tailored to meet individual financial goals."
In conclusion, the firm should revise its marketing materials to remove or modify the claims of consistent performance results, client testimonials, and any language that could be construed as guaranteeing future performance. The firm should ensure that all marketing communications are fair, balanced, and not misleading, in line with SEC regulations.
*/

/*
Upon reviewing the extracted information from the marketing text written by a FINRA Registered Representative, several potential issues arise that may conflict with FINRA marketing compliance rules and regulations. Below are the identified issues, explanations for why they are not compliant, and suggested solutions:

Claim of Consistently Delivered Remarkable Results:

Non-Compliance Issue: FINRA rules prohibit exaggerated or unwarranted claims. Stating that the firm has "consistently delivered remarkable results" could be considered a promissory statement and potentially misleading.
Solution: Modify the language to present a balanced view of performance, including any risks and the possibility of loss. Avoid promissory language.
Boasting an Annualized Return Rate of 25%:

Non-Compliance Issue: FINRA rules require that past performance data must be fair, balanced, and not misleading. Presenting an annualized return rate without proper context or disclosure of the risks and the possibility that investors may experience different results can be misleading.
Solution: Provide the time period over which the returns were achieved, disclose the risks, and include any relevant disclaimers. Ensure that the performance data is up to date and includes any material market or economic conditions that may have impacted the results.
Personalized Investment Strategies:

Potential Issue: While emphasizing a personalized approach is not inherently non-compliant, it must be ensured that the claims are not overstated or imply guaranteed success.
Solution: Clarify that while the firm aims to tailor strategies to individual goals, there are no guarantees, and each investor's results may vary.
Description of the Team as Industry Veterans:

Potential Issue: Describing the team as "industry veterans" is generally acceptable, but it should not imply that their experience guarantees future performance or success.
Solution: Ensure that the description of the team's experience is factual and does not suggest guaranteed results.
Commitment to Clients' Success and Invitation to Financial Prosperity:

Non-Compliance Issue: The language used here could be interpreted as promissory, suggesting that the firm can ensure financial prosperity for its clients.
Solution: Reframe the language to focus on the firm's dedication to working towards clients' goals without implying guaranteed success or prosperity.
In conclusion, the marketing text contains several statements that could be considered non-compliant with FINRA marketing rules and regulations due to their promissory nature and lack of balanced disclosures. To align with FINRA's requirements, the firm should revise the marketing material to include appropriate risk disclosures, avoid exaggerated claims, and ensure that any performance data presented is fair, balanced, and not misleading.
*/
