import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomButton } from "../components/CustomButton";
import CustomTextArea from "../components/CustomTextArea";
import { InfoIconWithText } from "../components/InfoIcon";
import Markdown from "../components/Markdown";

import { useDocumentReviewHandler } from "../logic/documentReview";
import { useFileUploadHandler } from "../logic/fileUpload";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import {
  getDocumentStatus,
  requestSignedURL,
  uploadDocument,
} from "../services/fileUpload.js";
import "./DocumentAnalysis.css";
import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import * as knowledgeBaseServices from "../services/knowledge";
import CustomDropdown from "../components/CustomDropdown.js";

const DocumentAnalysis = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const inputFile = useRef(null);

  const [dropdownVal, setdropdownVal] = useState(null);
  const [documents, setDocuments] = useState([]);

  const idToken = useAppSelector((state) => state.auth.idToken);
  const user = useAppSelector((state) => state.auth.user);
  const kbID = user.knowledge_bases.find(
    (kb) => kb.name === KnowledgeBaseType.DocumentAnalyst
  ).id;
  async function getData() {
    // setIsLoading(true);
    const [response, status] =
      await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, kbID);
    if (status == 200 || status == 204) {
      // setNumDocuments(response.documents.length);
      // setKnowledgeBaseFiles(response.documents);
      // setIsLoading(false);
      setDocuments(response.documents);
    } else {
      console.log("Error fetching data");
      // alert("Error fetching data");
    }
  }

  // Check the redux store for saved review info
  const readingInstructionsFromRedux = useSelector(
    (state) => state.documentAnalysis.readingInstructions
  );
  const reviewInstructionsFromRedux = useSelector(
    (state) => state.documentAnalysis.reviewInstructions
  );
  const finalReviewFromRedux = useSelector(
    (state) => state.documentAnalysis.finalReview
  );

  const fileTypes = ["txt", "pdf", "docx", "md", "mp3", "wav", "m4a", "epub"];

  const [isLoading, setIsLoading] = useState(false);

  const [readingInstructions, setReadingInstructions] = useState("");
  const [reviewInstructions, setReviewInstructions] = useState("");

  const {
    handleFileUpload,
    setAllUploadData,
    resetFileData,
    removeFile,
    fileData,
    fileHashes,
    allUploadData,
  } = useFileUploadHandler();

  const {
    runDocumentAnalysis,
    progress,
    showProgress,
    resultData,
    setResultData,
  } = useDocumentReviewHandler(setIsLoading);

  function onFileUploadButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  function changeDropdownVal(val) {
    setdropdownVal(val);
  }
  function handleChange(files) {
    let fileList = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
    }
    const object = {
      target: {
        files: fileList,
      },
    };
    handleFileUpload(object);
    setdropdownVal(null);
  }

  async function pollForDocumentStatus(resData) {
    // Send the document for review
    const documentId = resData["id"];
    console.log("documentId", documentId);
    // Sleep for 1 second
    await new Promise((r) => setTimeout(r, 1000));
    // Get the document status
    let [newResData, newStatus] = await getDocumentStatus(
      idToken,
      kbID,
      documentId
    );
    console.log("newResData", newResData);

    let documentStatus = newResData["vectorization_status"];

    let pollCount = 0;
    if (documentStatus === "FAILED") {
      // Check if the error is that the document already exists
      alert("Error uploading text");
      setIsLoading(false);
      return;
    }
    while (documentStatus !== "COMPLETE" && pollCount < 120) {
      // Sleep for 1 second
      await new Promise((r) => setTimeout(r, 1000));
      [newResData, newStatus] = await getDocumentStatus(
        idToken,
        kbID,
        documentId
      );
      console.log("newResData", newResData);

      let newDocumentStatus = newResData["vectorization_status"];
      documentStatus = newDocumentStatus;
      pollCount += 1;
    }

    if (documentStatus === "COMPLETE") {
      // Start the document review now
      console.log("Starting document review");
      runDocumentAnalysis(
        idToken,
        kbID,
        documentId,
        readingInstructions,
        reviewInstructions
      );
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  async function startDocumentReview() {
    if (allUploadData.length > 0) {
      setIsLoading(true);

      let fileName = allUploadData[0]["name"];
      const [response, urlStatus] = await requestSignedURL(
        idToken,
        fileName,
        kbID,
        fileHashes[fileName]
      );
      let signedURL = "";
      //const documentData = response.document
      if (response.error) {
        // Check if the error is that the document already exists

        if (
          response.existing_document_id !== undefined &&
          response.existing_document_id !== null
        ) {
          pollForDocumentStatus({
            id: response.existing_document_id,
          });
        } else {
          setIsLoading(false);
          alert("There was an error uploading this document");
        }
      } else {
        signedURL = response.temporary_url;

        const status = await uploadDocument(
          signedURL,
          fileHashes[fileName],
          fileData[fileName]
        );
        const resData = response["document"];

        if (status === 200 && !resData.error) {
          pollForDocumentStatus(resData);
        } else {
          alert("Error uploading text");
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(true);
      const documentId = dropdownVal;
      runDocumentAnalysis(
        idToken,
        kbID,
        documentId,
        readingInstructions,
        reviewInstructions
      );
    }
  }

  // "File chats.txt already exists in knowledge base: 239aa451-cc85-447a-aa1d-4464d2f813a5."
  useEffect(() => {
    // Set the allFileUpload variable based on the fileData
    // fileData is an object with the following structure:
    // {
    //     name: "file content",
    // }

    for (const filename in fileData) {
      // Make sure this filename isn't already in the allUploadData array
      if (allUploadData.filter((file) => file.name === filename).length > 0) {
        continue;
      }
      setAllUploadData((prevState) => {
        return [
          {
            name: filename,
            type: "file",
            chunk_header: "",
            auto_context: true,
            document_title: "",
            link_to_source: "",
            supp_id: "",
            description: "",
          },
          ...prevState,
        ];
      });
    }
  }, [fileData]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // If the readingInstructionsFromRedux is not null, set the readingInstructions state variable
    if (readingInstructionsFromRedux !== null) {
      setReadingInstructions(readingInstructionsFromRedux);
    }
  }, [readingInstructionsFromRedux]);

  useEffect(() => {
    // If the reviewInstructionsFromRedux is not null, set the reviewInstructions state variable
    if (reviewInstructionsFromRedux !== null) {
      setReviewInstructions(reviewInstructionsFromRedux);
    }
  }, [reviewInstructionsFromRedux]);

  useEffect(() => {
    // If the finalReviewFromRedux is not null, set the finalReview state variable
    if (finalReviewFromRedux !== null) {
      setResultData(finalReviewFromRedux);
    }
  }, [finalReviewFromRedux]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"document analysis"} />

      <div className="compliance-review-section">
        {/* <Header darkMode={false} currentPage={"document-analysis"} /> */}

        <div className="compliance-review-container">
          {isLoading && <BouncingDots />}

          <div className="compliance-review-content-container">
            <p className="compliance-review-header-text">Document Analysis</p>

            <div style={{display:"flex"}}>
              {allUploadData.length === 0 && (
                <div className="document-upload-container">
                  <InfoIconWithText
                  text={"Document"}
                  helpBoxTitle={"Document"}
                  helpBoxText={
                    "Select the document you want to use for this task."
                  }
                />

                  <CustomDropdown
                    valueList={documents.map((doc) => doc.id)}
                    textList={documents.map((doc) => doc.file_name)}
                    value={dropdownVal}
                    sendDataToParent={(val) => changeDropdownVal(val)}
                    customStyle={{ width: "200px", maxHeight: "400px" }}
                  />
                </div>
              )}

              {allUploadData.length === 0 && (
                <div className="document-upload-container">
                  <p className="semi-bold-font-light">
                    Upload document for review
                  </p>

                  <div
                    className={`alternate-upload-method-choice-light`}
                    onClick={onFileUploadButtonClick}
                    style={{marginTop:"0px",    minHeight: "1.4375em",height:"auto"}}
                  >
                    <IconContext.Provider
                      value={{
                        size: "18px",
                        color: "#383838",
                      }}
                    >
                      <MdOutlineFileUpload />
                    </IconContext.Provider>
                    <input
                      onChange={(event) => handleChange(event.target.files)}
                      type="file"
                      accept={fileTypes
                        .map((fileType) => "." + fileType)
                        .join(",")}
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                    <p className={`alternate-upload-method-text-light`}>
                      Browse Files
                    </p>
                  </div>
                </div>
              )}
            </div>
            {allUploadData.length === 0 && (
              <p className="valid-file-types-text">
                {`Valid file types are: .txt, .pdf, .docx, .md, .mp3, .wav, .m4a, and .epub`}
              </p>
            )}

            <div className="document-upload-container">
              <div>
                {allUploadData.length > 0 && (
                  <p className={`semi-bold-font-light`}>Document to review:</p>
                )}
                {allUploadData.map((file, index) => (
                  <div key={index} className="file-upload-container">
                    <p className="regular-font-light">{file.name}</p>
                    <div
                      style={{
                        marginLeft: "20px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => removeFile(file.name)}
                    >
                      <IconContext.Provider
                        value={{
                          size: "18px",
                          color: "#383838",
                        }}
                      >
                        <IoIosCloseCircle />
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
              </div>
            </div>

        

            <div>
              <div className="document-review-input-container">
                <InfoIconWithText
                  text={"Active reading instructions"}
                  helpBoxTitle={"Active reading instructions"}
                  helpBoxText={
                    "Detailed instructions about what the model should be paying attention to when reading through the documents."
                  }
                />
                <CustomTextArea
                  placeholderText={`Tell the AI what to pay attention to while reading through the document(s)`}
                  text={readingInstructions}
                  sendDataToParent={(text) => setReadingInstructions(text)}
                  rows={7}
                  useAutoResize={false}
                />
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <div className="document-review-input-container">
                <InfoIconWithText
                  text={"Final review instructions (optional)"}
                  helpBoxTitle={"Final review instructions"}
                  helpBoxText={
                    "Detailed instructions about what the final output should look like."
                  }
                />
                <CustomTextArea
                  placeholderText={`Tell the AI what to write in the final review`}
                  text={reviewInstructions}
                  sendDataToParent={(text) => setReviewInstructions(text)}
                  rows={7}
                  useAutoResize={false}
                />
              </div>
            </div>

            <div className="submit-review-button-container">
              <CustomButton
                onClick={() => startDocumentReview()}
                buttonText={"Submit Review"}
                disabled={
                  readingInstructions === "" ||
                  isLoading ||
                  (allUploadData.length === 0 && dropdownVal === null)
                }
              />
            </div>
          </div>

          <div className="compliance-review-result-container">
            {resultData != null && (
              <div className="document-review-result-container">
                <div className="document-review-result-text">
                  <p className={`semi-bold-font-light`}>Final review</p>
                </div>
                <Markdown
                  markdownContent={resultData}
                  className={`regular-font-light document-review-result-text`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentAnalysis;
