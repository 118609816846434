const { REACT_APP_AI_URL } = process.env;

export async function queryKnowledgeBase(
  knowledgeBaseIds,
  idToken,
  query,
  systemMessage
) {
  const payload = {
    knowledge_base_ids: knowledgeBaseIds,
    query: query,
    top_k: 10,
    exclude_irrelevant_results: true,
    summarize_results: true,
    // summary_config: {
    //     system_message: systemMessage,
    // },
    // Todo
    // use_auto_query: true,
    use_rse: true,
    segment_length: "medium",
    // auto_query_guidance: ""
  };
  console.log("payload", payload);

  // REACT_APP_AI_URL
  const response = await fetch(`${REACT_APP_AI_URL}/knowledge_bases/query`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  console.log("data", data);
  return [data, response.status];
}

// const { payload } = req.body;

// let url = `https://api.superpowered.ai/v1/knowledge_bases/query`;
// const authToken = Buffer.from(`${process.env.EXTERNAL_API_KEY}:${process.env.EXTERNAL_API_SECRET}`).toString('base64');

// try {
//     // Make the request to the external API
//     const externalApiResponse = await axios.post(url, payload, {
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": authToken
//         }
//     });
//     res.json(externalApiResponse.data);
// } catch (error) {
//     // Handle errors, such as network issues or invalid responses
//     console.error('Error fetching external API data:', error);
//     res.status(500).json({ message: 'Error fetching external data' });
// }

// })
// })
