import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import {
  setIdToken,
  setKnowledgeBases,
  setUser,
} from "../features/auth/authSlice.ts";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { sendToken } from "../services/auth";
import { updateKnowledgeBase } from "../services/chat.tsx";
import { createKnowledgeBases } from "../services/knowledgebases.tsx";
import useQuery from "../utils/hooks/useQuery";

const LoginRedirect = () => {
  let query = useQuery("token");
  const [loading, setLoading] = useState(true);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    sendToken(query).then(async (res) => {
      if (res[1] === 200) {
        dispatch(setIdToken(query.toString()));
        dispatch(setUser(res[0].data));
        console.log("res", res[0].data);
        if (
          !res[0].data.knowledge_bases ||
          res[0].data.knowledge_bases.length === 0
        ) {
          const newKnowledgeBase = await createKnowledgeBases(
            query.toString(),
            res[0].data._id
          );
          if (newKnowledgeBase) {
            const new_user = await updateKnowledgeBase(
              query.toString(),
              res[0].data._id,
              res[0].data.companyId,
              newKnowledgeBase
            );
            dispatch(setKnowledgeBases(newKnowledgeBase));
            history.push("/");
          }
        } else {
          history.push("/");
        }
      } else {
        window.location.href = process.env.REACT_APP_WEALTHIO_URL;
      }
    });
    setLoading(false);
  }, []);
  return (
    <div>
      {loading ? (
        <BouncingDots />
      ) : (
        <div>{user ? user.username : "No user found"}</div>
      )}
    </div>
  );
};

export default LoginRedirect;
