import { Route } from "react-router-dom";
import { useAppSelector } from "./hooks.ts";
import React from "react";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const idToken = useAppSelector((state) => state.auth.idToken);

  return (
    <Route
      {...rest}
      render={(props) =>
        idToken ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
