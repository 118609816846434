import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Login from "../screens/Login";
import Chat from "../screens/Chat";
import Compliance from "../screens/Compliance";
import Archive from "../screens/Archive";
import LongForm from "../screens/LongForm";
import LoginRedirect from "../screens/LoginRedirect";
import ProtectedRoute from "../guard.tsx";
import DocumentAnalysis from "../screens/DocumentAnalysis.js";
import AdvisorAssist from "../screens/AdvisorAssist.js";
import Knowledge from "../screens/Knowledge.js";
import EditKnowledgeBase from "../screens/EditKnowledgeBase.js";
import DocumentUpload from "../screens/DocumentUpload.js";

const MainNavigator = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/knowledge" exact>
                    <Knowledge />
                </Route>
                <Route path="/knowledge/:title" exact>
                    <EditKnowledgeBase />
                </Route>
                <Route path="/document-upload/:title" exact>
                    <DocumentUpload />
                </Route>
        <Route path="/login-redirect" exact>
          <LoginRedirect />
        </Route>
    
        <ProtectedRoute
          path="/advisor-assist"
          exact
          component={AdvisorAssist}
        />
        <ProtectedRoute path="/" exact component={Chat} />
        <ProtectedRoute path="/compliance" exact component={Compliance} />
        <ProtectedRoute path="/archive" exact component={Archive} />
        <ProtectedRoute path="/long-form" exact component={LongForm} />
        <ProtectedRoute
          path="/document-analysis"
          exact
          component={DocumentAnalysis}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default MainNavigator;
