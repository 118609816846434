import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faComments, // Added for Chat
  faGavel, // Added for Compliance
  faArchive, // Added for Archive
  faFileAlt, // Added for Long Form
  faUserTie,
  faSignOutAlt,
  faFile,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import "./Sidebar.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks.ts";
import { logout } from "../../features/auth/authSlice.ts";
import { ReactComponent as WealthLogo } from "../../assets/logo-white-wealth.svg";
import { ReactComponent as WealthLogoShort } from "../../assets/logo-short.svg";

const menuItems = [
  { title: "Post Generator", icon: faComments, path: "/" },
  {
    title: "Fine-Tuning",
    icon: faBrain,
    path: "/knowledge",
  },
  { title: "Long Form", icon: faFileAlt, path: "/long-form" },
  { title: "Compliance", icon: faGavel, path: "/compliance" },
  { title: "Archive", icon: faArchive, path: "/archive" },
  { title: "Document Analysis", icon: faFile, path: "/document-analysis" },
  {
    title: "PDF Search",
    icon: faUserTie,
    path: "/advisor-assist",
  },

  // { title: "Logout", icon: faSignOutAlt, action: "logout" },
];

const Sidebar = ({ currentPage, onPersonalizationClick }) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory(); // Use the useHistory hook
  // const dispatch = useDispatch(); // Use the useDispatch hook for actions
  const dispatch = useAppDispatch(); // Use the useDispatch hook for actions
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    const condition =
      user &&
      user.companyId &&
      user.companyId.toString() === "63854e726df8f59091075384";
    if (condition) {
      const complianceIndex = menuItems.findIndex(
        (item) => item.title === "Compliance"
      );
      if (complianceIndex !== -1) {
        menuItems.splice(complianceIndex, 1);
      }
    }
  }, [user]);
  const navigate = (path) => {
    history.push(path);
  };

  const handleItemClick = (item) => {
    if (item.action === "logout") {
      // dispatch(authActions.logout(history));
      dispatch(logout());
    } else {
      navigate(item.path);
    }
  };

  return (
    <div className={cx("sidebar", { "sidebar-closed": !isOpen })}>
      <div>
        <button
          className={"sidebar__button"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon icon={faBars} />
          {isOpen && (
            <img
              className={`advisor-x-logo-dark`}
              style={{ width: "100%" }}
              loading="lazy"
              src="https://assets-global.website-files.com/629e3357919ae1682f65b07c/6532d8b6ece624b92226971a_main-logo-big-advisorx.svg"
              alt=""
            />
          )}
        </button>
        {isOpen ? <br /> : <></>}
        <ul>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CSSTransition
              in={isOpen}
              timeout={200}
              classNames={"fade"}
              unmountOnExit
            >
              <span style={{ marginRight: "10px" }}>{"Switch to"}</span>
            </CSSTransition>
          </div>
          <li
            onClick={() => window.location.replace("https://app.wealthio.com")}
            style={{ marginTop: "10px" }}
          >
            {isOpen ? (
              <div className={"sidebar__listItem"}>
                <WealthLogo />
              </div>
            ) : (
              <div className="sidebar__icon">
                <WealthLogoShort
                  style={{
                    width: "200px",
                    height: "40px",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </li>
          <hr style={{ marginBottom: "20px" }} />
          {menuItems.map((item) => (
            <li
              key={item.title}
              onClick={() => handleItemClick(item)}
              className={
                currentPage === item.title?.toLowerCase()
                  ? "sidebar__listItem--selected"
                  : ""
              }
            >
              <div className={"sidebar__listItem"}>
                <FontAwesomeIcon className={"sidebar__icon"} icon={item.icon} />
                <CSSTransition
                  in={isOpen}
                  timeout={200}
                  classNames={"fade"}
                  unmountOnExit
                >
                  <span>{item.title}</span>
                </CSSTransition>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {isOpen && onPersonalizationClick ? (
          <div
            className="prompts-button"
            onClick={onPersonalizationClick ? onPersonalizationClick : () => {}}
          >
            Personalization
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
