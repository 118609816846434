import { includedDomains } from "../utils/included_domains.ts";

const { REACT_APP_SERVICE_BASE_URL, REACT_APP_API_URL, REACT_APP_AI_URL } =
  process.env;

export async function generateLongForm(
  knowledgeBaseIds,
  idToken,
  model,
  autoQueryGuidance,
  prompt,
  segmentLength,
  temperature,
  responseLength,
  username,
  use_web_search,
  web_search_preset
) {
  let formattedTemperature = temperature;
  if (typeof (temperature == "string")) {
    formattedTemperature = parseFloat(temperature);
  }
  const payload = {
    knowledge_base_ids: knowledgeBaseIds,
    model: model,
    prompt: prompt,
    segment_length: segmentLength,
    auto_query_guidance: autoQueryGuidance,
    response_length: responseLength,
    temperature: formattedTemperature,
    supp_id: username,
  };

  const web_search_preset_config = {};
  if (use_web_search) {
    payload["use_web_search"] = true;
    if (web_search_preset && web_search_preset.include_domains) {
      if (web_search_preset.include_domains.length > 0) {
        web_search_preset_config["include_domains"] =
          web_search_preset.include_domains;
      }
    } else if (web_search_preset && web_search_preset.exclude_domains) {
      if (web_search_preset.exclude_domains.length > 0) {
        web_search_preset_config["exclude_domains"] =
          web_search_preset.exclude_domains;
      }
    }

    if (
      web_search_preset &&
      web_search_preset.timeframe_days &&
      typeof web_search_preset.timeframe_days === "number"
    ) {
      web_search_preset_config["timeframe_days"] =
        web_search_preset.timeframe_days;
    }
    payload["web_search_config"] = web_search_preset_config;
  }

  const response = await fetch(`${REACT_APP_AI_URL}/long_form`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  console.log("data", data);
  console.log("status", response.status);
  return [data, response.status];
}

export async function pollGenerateResponse(idToken, jobID) {
  // let url = `${REACT_APP_API_URL}/poll_status`;

  const pollURL = `${REACT_APP_AI_URL}/long_form/${jobID}`;
  const payload = {
    pollURL: pollURL,
  };

  const response = await fetch(pollURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload)
  });

  const resData = await response.json();
  console.log("resData", resData);
  const status = response.status;

  return [resData, status];
}

export async function listGenerations(idToken, nextPageToken = null, username) {
  let url = "";
  url = `${REACT_APP_AI_URL}/long_form`;

  if (username !== undefined && username !== null) {
    url += `?supp_id=${username}`;
  }
  if (nextPageToken !== undefined && nextPageToken !== null) {
    if (username !== undefined && username !== null) {
      url += "&";
    } else {
      url += "?";
    }
    url += `next_page_token=${nextPageToken}`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload)
  });

  const resData = await response.json();
  console.log("long from generations", resData);
  return [resData, response.status];
}

export async function listExports(idToken, generationID, nextPageToken = null) {
  let url = "";
  url = `${REACT_APP_SERVICE_BASE_URL}/long_form/${generationID}/exports`;
  if (nextPageToken !== null) {
    url += `?next_page_token=${nextPageToken}`;
  }
  console.log("url", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });

  const resData = await response.json();
  console.log("long from exports", resData);
  return [resData, response.status];
}

export async function listPendingExports(
  idToken,
  generationID,
  nextPageToken = null
) {
  let url = "";
  url = `${REACT_APP_SERVICE_BASE_URL}/long_form/${generationID}/pending_exports`;
  if (nextPageToken !== null) {
    url += `?next_page_token=${nextPageToken}`;
  }
  console.log("url", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });

  const resData = await response.json();
  console.log("pending long from exports", resData);
  return [resData, response.status];
}

export async function getGeneration(idToken, jobID) {
  // let url = `${REACT_APP_AI_URL}/get_generation`;
  // let url = `https://api.superpowered.ai/v1/long_form/${generationId}`;
  let url = `${REACT_APP_AI_URL}/long_form/${jobID}`;

  const payload = {
    generationId: jobID,
  };

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    // body: JSON.stringify(payload)
  });

  const resData = await response.json();
  console.log("long from generations", resData);
  return [resData, response.status];
}

export async function deleteGeneration(idToken, jobID) {
  let url = `${REACT_APP_AI_URL}/long_form/${jobID}`;

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });

  const status = response.status;
  console.log(status);
  return status;
}

export async function exportToText(idToken, jobID, exportType) {
  const url = `${REACT_APP_SERVICE_BASE_URL}/long_form/${jobID}/exports/text?format=${exportType}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });
  const data = await response.json();
  console.log("data", data);
  console.log("status", response.status);
  return [data, response.status];
}

export async function exportToAudio(idToken, jobID, audioVoice) {
  let url = `${REACT_APP_SERVICE_BASE_URL}/long_form/${jobID}/exports/audio`;

  const payload = {
    audio_voice_name: audioVoice,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  console.log("data", data);
  console.log("status", response.status);
  return [data, response.status];
}

export async function pollExportJob(idToken, jobID) {
  // const pollURL = `${REACT_APP_SERVICE_BASE_URL}/jobs/${jobID}`
  const pollURL = `${REACT_APP_AI_URL}/poll_status?job_id=${jobID}`;

  const response = await fetch(pollURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });

  const resData = await response.json();
  return [resData, response.status];
}

export async function updateGeneration(
  idToken,
  generationID,
  title,
  content,
  sectionIndex
) {
  const url = `${REACT_APP_AI_URL}/long_form/${generationID}`;

  const payload = {
    sections: [
      {
        index: sectionIndex,
        title: title,
        content: content,
      },
    ],
  };

  console.log("payload", payload);

  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  console.log("data", data);
  console.log("status", response.status);
  return [data, response.status];
}
