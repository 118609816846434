import React from "react";
import { Provider } from "react-redux";
import MainNavigator from "./navigation/MainNavigator";

import "./App.css";
import "./styles/generalStyles.css";
import { store } from "./store.ts";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastProvider } from "./context/ToastContext";

function App() {
  let persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastProvider>
          <MainNavigator />
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
