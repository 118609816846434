import React from 'react';

import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';


const CustomDropdown = ({ valueList, textList, value, sendDataToParent, customStyle, isDisabled = false, themeOverride = null }) => {

    const theme = {
        theme: "light",
        backgroundColor: "var(--off-white)",
        mainFontColor: "var(--off-black)",
        sidenavBackgroundColor: "#FFFFFF",
        headerBorder: "1px solid #e0e0e0",
    }
    const styleTheme = themeOverride == null ? theme : themeOverride;

    const handleChange = (event) => {
        sendDataToParent(event.target.value);
    };

    const DropdownMenuItem = styled(MenuItem)(({ theme }) => ({

        fontFamily: "Inter",
        fontSize: "14px",
        color: styleTheme.mainFontColor,
        backgroundColor: styleTheme.sidenavBackgroundColor,
        '&.Mui-disabled': {
            color: "#8F8F8F",
        },
    }));

    const DropdownInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: styleTheme.sidenavBackgroundColor,
            border: (styleTheme.theme == "light" ? '1px solid var(--divider-line-gray)' : "none"),
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                borderRadius: 4,
                borderColor: 'var(--divider-line-gray)',
                boxShadow: 'none',
            },
        },
        '&MuiList-root-MuiMenu-list': {
            backgroundColor: styleTheme.sidenavBackgroundColor,
        },
        '& .MuiSelect-icon': {
            color: styleTheme.mainFontColor
        }
    }));

    return (
        <div style={{ width: "100%" }}>
            <FormControl sx={{ ...customStyle }} size="small" disabled={isDisabled}>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value}
                    onChange={(event) => handleChange(event)}
                    input={<DropdownInput />}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: styleTheme.sidenavBackgroundColor,
                                    maxHeight: customStyle.maxHeight === undefined ? "100%" : customStyle.maxHeight,
                                }
                            }
                        }
                    }}
                    style={{
                        paddingTop: "0px",
                        borderRadius: 4,
                        paddingBottom: "0px",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: (isDisabled ? "#8F8F8F" : styleTheme.mainFontColor),
                        backgroundColor: styleTheme.sidenavBackgroundColor,

                    }}
                >

                    {valueList.map((item, index) =>
                        <DropdownMenuItem
                            key={index}
                            value={item}
                            disabled={isDisabled}
                        >
                            {textList[index]}
                        </DropdownMenuItem>
                    )}

                </Select>
            </FormControl>
        </div>
    )

}

/*
{valueList.map((item, index) =>
                        <MenuItem
                            key={index}
                            value={item}
                            disabled={isDisabled}
                            style={{ maxWidth: customStyle.width === undefined ? "100%" : customStyle.width, overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontFamily: "Inter", fontSize: "14px", color: styleTheme.mainFontColor, backgroundColor: styleTheme.sidenavBackgroundColor }}>
                            {textList[index]}
                        </MenuItem>
                    )}
                    */

export default CustomDropdown;