import React, { useState, useEffect, useRef } from "react";

import "./CustomTextArea.css";

const CustomTextArea = (props) => {
  const [message, setMessage] = useState(props && props.text ? props.text : "");
  const textAreaRef = useRef(null);

  const handleChange = (evt) => {
    const val = evt.target?.value;
    if (props.maxChars !== undefined && val.length > props.maxChars) return;
    setMessage(val);
    props.sendDataToParent(val);
  };

  // Autosize the textarea
  const useAutosizeTextArea = (textAreaRef, message) => {
    useEffect(() => {
      const textAreaElement = textAreaRef.current;
      if (textAreaElement) {
        const savedScrollPos = window.scrollY;

        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaElement.style.height = "0px";
        const scrollHeight = textAreaElement.scrollHeight;

        // Handle the rows input to set a minimum height for the textarea
        const rows = props.rows;
        const minHeight = rows === undefined ? 1 : rows;
        const minHeightPx =
          props.minHeight === undefined ? 118 : props.minHeight;

        // We then set the height directly, outside of the render loop
        textAreaElement.style.height =
          Math.max(scrollHeight, minHeightPx) + "px";

        // Restore the scroll position
        window.scrollTo(0, savedScrollPos);
      }
    }, [textAreaRef, message]); // Depend on textAreaRef itself, not its current value
  };

  useAutosizeTextArea(textAreaRef, message);

  useEffect(() => {
    setMessage(props.text);
  }, [props.text]);

  return (
    <div
      className="custom-text-area-section"
      style={props.customContainerStyle}
    >
      <div className="custom-text-area-container">
        <textarea
          className="custom-text-area"
          style={props.customTextAreaStyle}
          onChange={handleChange}
          placeholder={
            props.placeholderText === undefined
              ? "Enter text"
              : props.placeholderText
          }
          ref={textAreaRef}
          rows={props.rows === undefined ? 1 : props.rows}
          value={message === undefined ? "" : message}
        />
        {message && message.length > 0 && props.maxChars !== undefined && (
          <p className={`light-font-light`}>
            {message.length}/{props.maxChars} characters
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomTextArea;
