import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { IconContext } from "react-icons";
import { MdOutlineFileUpload } from "react-icons/md";

import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner.tsx";
import { CustomAddButton } from "../components/CustomButton";

import { ClickableRow } from "../components/ClickableRow";
import { useFileUploadHandler } from "../logic/fileUpload";
import { getKnowledgeBaseFiles } from "../services/knowledge";

import "./Knowledge.css";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useHistory } from "react-router-dom";

const Knowledge = () => {
  const history = useHistory();

  // Get the api key and secret from session storage
  let data = sessionStorage.getItem("data");
  if (data === undefined || data === null) {
    data = {};
  } else {
    data = JSON.parse(data);
  }
  const advisorKbID = data["advisor_kb_id"];
  const complianceKbID = data["compliance_kb_id"];
  const brandPersonalizationKbID = data["brand_kb_id"];

  const [isLoading, setIsLoading] = useState(false);
  const [showComplianceKB, setShowComplianceKB] = useState(false);
  const [showBrandPersonalizationKB, setShowBrandPersonalizationKB] =
    useState(false);
  const [showAdvisorAssistKB, setShowAdvisorAssistKB] = useState(false);

  const [advisorKbFiles, setAdvisorKbFiles] = useState([]);
  const [complianceKbFiles, setComplianceKbFiles] = useState([]);
  const [brandPersonalizationKbFiles, setBrandPersonalizationKbFiles] =
    useState([]);

  function deselectAll() {
    setShowComplianceKB(false);
    setShowBrandPersonalizationKB(false);
    setShowAdvisorAssistKB(false);
  }

  function toggleVisibility(knowledgeBase) {
    setShowComplianceKB(false);
    setShowBrandPersonalizationKB(false);
    setShowAdvisorAssistKB(false);
    if (knowledgeBase === "compliance") {
      setShowComplianceKB(!showComplianceKB);
    } else if (knowledgeBase === "brandPersonalization") {
      setShowBrandPersonalizationKB(!showBrandPersonalizationKB);
    } else if (knowledgeBase === "advisorAssist") {
      setShowAdvisorAssistKB(!showAdvisorAssistKB);
    }
  }

  async function requestKbDocuments() {
    setIsLoading(true);
    let advisorKbFiles = await getKnowledgeBaseFiles(advisorKbID);
    setAdvisorKbFiles(advisorKbFiles);
    let complianceKbFiles = await getKnowledgeBaseFiles(complianceKbID);
    setComplianceKbFiles(complianceKbFiles);
    let brandPersonalizationKbFiles = await getKnowledgeBaseFiles(
      brandPersonalizationKbID
    );
    setBrandPersonalizationKbFiles(brandPersonalizationKbFiles);
    setIsLoading(false);
  }

  useEffect(() => {
    // Get the files for the each knowledge base
    //requestKbDocuments()
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"fine-tuning"} />

      <div className="knowledge-base-section">
        <div className="knowledge-bases-container">
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p className="regular-font-light">Fine Tuning Management</p>
            </div>

            <div className="knowledge-base-list-container">
              <ClickableRow
                numCols={3}
                allText={[
                  "Compliance",
                  "Upload your firm's compliance manual or state compliance documents.",
                ]}
                flexSizing={[1, 4]}
                onClick={() => history.push("/knowledge/compliance")}
              />
            </div>

            <div className="knowledge-base-list-container">
              <ClickableRow
                numCols={3}
                allText={[
                  "Brand Personalization",
                  "Upload firm-specific content like blogs or articles so our AI can adapt to your brand tone.",
                ]}
                flexSizing={[1, 4]}
                onClick={() => history.push("/knowledge/brand-personalization")}
              />
            </div>

            <div className="knowledge-base-list-container">
              <ClickableRow
                numCols={3}
                allText={[
                  "Document Analysis",
                  "Upload documents or marketing materials that you want to review for compliance.",
                ]}
                flexSizing={[1, 4]}
                onClick={() => history.push("/knowledge/document-analysis")}
              />
            </div>

            <div className="knowledge-base-list-container">
              <ClickableRow
                numCols={3}
                allText={[
                  "PDF Search",
                  "Upload firm-specific documents or manuals that you want to fetch answers from.",
                ]}
                flexSizing={[1, 4]}
                onClick={() => history.push("/knowledge/advisor-assist")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KbDisplay = ({ kbFiles, onDoneClick }) => {
  const fileTypes = [
    "txt",
    "pdf",
    "docx",
    "md",
    "mp3",
    "mp4",
    "wav",
    "m4a",
    "epub",
  ];

  const inputFile = useRef(null);

  const {
    handleFileUpload,
    handleURLUpload,
    setAllUploadData,
    resetFileData,
    removeFile,
    fileData,
    fileHashes,
    allUploadData,
  } = useFileUploadHandler();

  function handleChange(files) {
    let fileList = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
    }
    const object = {
      target: {
        files: fileList,
      },
    };
    handleFileUpload(object);
  }

  function onFileUploadButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  return (
    <div className="">
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        multiple
        disabled
      >
        <div className={`file-upload-container`}>
          <IconContext.Provider
            value={{
              size: "32px",
              color: "#383838",
            }}
          >
            <MdOutlineFileUpload />
          </IconContext.Provider>
          <div className="drag-and-drop-row">
            <p className={`regular-font-light drag-files-text`}>
              Drag files here
            </p>
          </div>
        </div>
      </FileUploader>

      <div
        className={`alternate-upload-method-choice-light`}
        onClick={onFileUploadButtonClick}
        style={{ marginRight: "15px" }}
      >
        <IconContext.Provider
          value={{
            size: "18px",
            color: "#383838",
          }}
        >
          <MdOutlineFileUpload />
        </IconContext.Provider>
        <input
          onChange={(event) => handleChange(event.target.files)}
          type="file"
          accept={fileTypes.map((fileType) => "." + fileType).join(",")}
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
        />
        <p className={`alternate-upload-method-text-light`}>Browse Files</p>
      </div>
    </div>
  );
};

export default Knowledge;
