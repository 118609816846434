import React from "react";
import { useLocation } from "react-router-dom";

export default function useQuery(
  name
) {
  const { search } = useLocation();
  if(!search) return null;
  if (name) {
    return new URLSearchParams(search).get(name);
  }
  return new URLSearchParams(search);
}
