import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as knowledgeBaseServices from "../services/knowledge";

import { IconContext } from "react-icons";
import { BiLeftArrowAlt } from "react-icons/bi";

import { CustomAddButton } from "../components/CustomButton";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { FileList } from "../components/FileList";

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import "./EditKnowledgeBase.css";

const EditKnowledgeBase = () => {
  const history = useHistory();
  const { title } = useParams();
  const data = useAppSelector((state) => state.auth.user);

  const knowledgeBaseIds = {
    compliance: data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.Compliance
    )[0].id,
    "brand-personalization": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.BrandPersonalization
    )[0].id,
    "document-analysis": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.DocumentAnalyst
    )[0].id,
    "advisor-assist": data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.AdvisorAssist
    )[0].id,
  };
  const kbNames = {
    compliance: "Compliance",
    "brand-personalization": "Brand Personalization",
    "document-analysis": "Document Analysis",
    "advisor-assist": "Advisor Assist",
  };
  // const knowledgeBaseFiles = useAppSelector(state => state.knowledge.knowledgeBaseFiles);

  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState([]);
  const idToken = useAppSelector((state) => state.auth.idToken);
  const knowledgeBaseId = knowledgeBaseIds[title];

  async function getData() {
    setIsLoading(true);
    const [response, status] =
      await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(
        idToken,
        knowledgeBaseId
      );
    if (status == 200 || status == 204) {
      console.log(response.documents, "response.documents");
      setNumDocuments(response.documents.length);
      setKnowledgeBaseFiles(response.documents);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("Error fetching data");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [numDocuments, setNumDocuments] = useState(0);
  async function deleteDocuments(filesToDelete) {
    // set
    setIsLoading(true);
    for (let i = 0; i < filesToDelete.length; i++) {
      const fileID = filesToDelete[i];
      const status = await knowledgeBaseServices.deleteDocument(
        idToken,
        knowledgeBaseId,
        fileID
      );
    }
    await getData();
  }

  const [selectedFiles, setSelectedFiles] = useState({});
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  function handleCheckboxClick(fileID) {
    setSelectedFiles({ ...selectedFiles, [fileID]: !selectedFiles[fileID] });
    if (selectedFiles[fileID]) {
      setFilesToDelete(filesToDelete.filter((file) => file !== fileID));
    } else {
      setFilesToDelete([...filesToDelete, fileID]);
    }
  }

  function createSelectedFilesObject(allFiles = []) {
    let selectedFilesObject = {};
    if (allFiles && allFiles.length > 0) {
      allFiles.forEach((file) => {
        selectedFilesObject[file.id] = false;
      });
      setSelectedFiles(selectedFilesObject);
    }
  }

  useEffect(() => {
    createSelectedFilesObject(knowledgeBaseFiles);
  }, [knowledgeBaseFiles]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"fine-tuning"} />
      <div className="knowledge-base-section">
        <div className="knowledge-bases-container">
          {isLoading && <BouncingDots />}

          {showDeleteConfirmation && (
            <DeleteConfirmationModal
              title={"Delete documents"}
              confirmationText={
                "Are you sure you want to delete the selected documents?"
              }
              closeModal={() => setShowDeleteConfirmation(false)}
              onCancel={() => setShowDeleteConfirmation(false)}
              delete={() => {
                deleteDocuments(filesToDelete);
                setShowDeleteConfirmation(false);
                setFilesToDelete([]);
              }}
            />
          )}

          <div className="edit-knowledge-base-header-container">
            <div className="edit-knowledge-base-header-row">
              <div
                className="edit-knowledge-base-back-container"
                onClick={() => history.goBack()}
              >
                <IconContext.Provider
                  value={{
                    className: `edit-knowledge-base-back-arrow-light`,
                  }}
                >
                  <BiLeftArrowAlt />
                </IconContext.Provider>
                <p className={`edit-knowledge-base-back-text-light`}>Back</p>
              </div>
            </div>

            <div className="edit-knowledge-base-header-row">
              <div className="edit-knowledge-base-title-container">
                <div>
                  <p className={`bold-font-larger-light`}>{kbNames[title]}</p>
                </div>
              </div>

              <div className="edit-knowledge-base-add-files-container">
                <CustomAddButton
                  buttonText={"Add documents"}
                  onClick={() => {
                    console.log("navigating");
                    history.push(`/document-upload/${title}`);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="edit-knowledge-base-info-container">
            <div className="knowledge-base-files-container">
              <div className="knowledge-base-files-container-header">
                <div>
                  <p
                    className={`semi-bold-font-light`}
                    style={{ fontSize: "18px" }}
                  >
                    {`${numDocuments} Document${numDocuments === 1 ? "" : "s"}`}
                  </p>
                </div>
                {filesToDelete.length > 0 ? (
                  <div className="knowledge-base-select-delete-container">
                    <p
                      className="knowledge-base-delete-files-text"
                      onClick={() => setShowDeleteConfirmation(true)}
                    >
                      {"Delete documents"}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <FileList
                files={isLoading ? [] : knowledgeBaseFiles}
                selectedFiles={selectedFiles}
                handleCheckboxClick={(fileID) => handleCheckboxClick(fileID)}
                onViewFileClick={(fileID, knowledgeBaseID) =>
                  console.log(fileID, knowledgeBaseID)
                }
                onEditFileClick={(fileID) => console.log(fileID)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditKnowledgeBase;
