import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";

import { CustomButton } from "../components/CustomButton";
import CustomTextArea from "../components/CustomTextArea";

import { formatSources } from "../logic/chat.tsx";
import { useFileUploadHandler } from "../logic/fileUpload";
import {
  getDocumentStatus,
  requestSignedURL,
  uploadDocument,
} from "../services/fileUpload";
import * as queryServices from "../services/query";

import CustomDropdown from "../components/CustomDropdown.js";
import { InfoIconWithText } from "../components/InfoIcon";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { useAppSelector } from "../hooks.ts";
import * as knowledgeBaseServices from "../services/knowledge";
import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import "./AdvisorAssist.css";

const AdvisorAssist = () => {
  const inputFile = useRef(null);
  const [dropdownVal, setdropdownVal] = useState(null);

  const [queryText, setQueryText] = useState("");
  const [documents, setDocuments] = useState([]);

  function changeDropdownVal(val) {
    setdropdownVal(val);
  }
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [citedResults, setCitedResults] = useState([]);
  const [resultSummary, setResultSummary] = useState("");
  const [searchQueries, setSearchQueries] = useState([]);

  const [resultTypeShown, setResultTypeShown] = useState("summary");
  const [showSummary, setShowSummary] = useState(true);
  const [showCitedResults, setShowCitedResults] = useState(true);

  const {
    handleFileUpload,
    setAllUploadData,
    resetFileData,
    removeFile,
    fileData,
    fileHashes,
    allUploadData,
  } = useFileUploadHandler();

  const summarizeResults = true;
  const fileTypes = [
    "txt",
    "pdf",
    "docx",
    "md",
    "mp3",
    "mp4",
    "wav",
    "m4a",
    "epub",
  ];

  let kbIDs = [];

  const idToken = useAppSelector((state) => state.auth.idToken);
  const user = useAppSelector((state) => state.auth.user);
  let customSystemMessage = user.system_message ? user.system_message : "";
  const kbID = user.knowledge_bases.find(
    (kb) => kb.name === KnowledgeBaseType.AdvisorAssist
  ).id;

  async function getData() {
    // setIsLoading(true);
    const [response, status] =
      await knowledgeBaseServices.getAllDocumentsInKnowledgeBase(idToken, kbID);
    if (status == 200 || status == 204) {
      // setNumDocuments(response.documents.length);
      // setKnowledgeBaseFiles(response.documents);
      // setIsLoading(false);
      setDocuments(response.documents);
    } else {
      console.log("Error fetching data");
      // alert("Error fetching data");
    }
  }

  const uploadKbId = user.knowledge_bases.find(
    (kb) => kb.name === KnowledgeBaseType.AdvisorAssist
  ).id;

  async function sendQuery() {
    setButtonDisabled(true);
    setResultData([]);
    setIsLoading(true);

    // add the uploadKbId to the list of kbIDs
    kbIDs.push(uploadKbId);
    const [response, status] = await queryServices.queryKnowledgeBase(
      [uploadKbId],
      idToken,
      queryText,
      customSystemMessage
    );

    if (status === 200) {
      const references = response.references;
      const rankedResults = response.ranked_results;
      let sources = [];
      if (rankedResults.length > 0 && references !== null) {
        sources = formatSources(references, rankedResults);
      }
      setCitedResults(sources);
      console.log("sources", sources);
      setResultData(response.ranked_results);
      setResultSummary(response.summary);
      setSearchQueries(response.search_queries);

      // If summarize results is set to false, we only want to show the
      if (!summarizeResults) {
        setResultTypeShown("allResults");
        setShowSummary(false);
        setShowCitedResults(false);
      } else {
        setResultTypeShown("summary");
        setShowSummary(true);
        setShowCitedResults(true);
      }
    } else {
      alert(
        "Sorry, we couldn't query the knowledge base at this time. Please try again later."
      );
    }
    setIsLoading(false);
    setButtonDisabled(false);
  }

  async function uploadDocumentToKnowledgeBase() {
    if (allUploadData.length === 0 && dropdownVal === null) {
      alert("Please upload a document or select a document from the dropdown");
      return;
    }
    if (allUploadData.length > 0) {
      setIsLoading(true);

      let fileName = allUploadData[0]["name"];

      const [response, urlStatus] = await requestSignedURL(
        idToken,
        fileName,
        uploadKbId,
        fileHashes[fileName]
      );
      console.log("signed url response", response);
      console.log("urlStatus", urlStatus);
      let signedURL = "";
      //const documentData = response.document
      if (response.error) {
        if (
          response.existing_document_id !== undefined &&
          response.existing_document_id !== null
        ) {
          pollForDocumentStatus({
            id: response.existing_document_id,
          });
        } else {
          setIsLoading(false);
          alert("There was an error uploading this document");
        }
      } else {
        signedURL = response.temporary_url;

        const status = await uploadDocument(
          signedURL,
          fileHashes[fileName],
          fileData[fileName]
        );
        console.log("status", status);

        const resData = response["document"];

        console.log("Starting document review");

        if (status === 200 && !resData.error) {
          pollForDocumentStatus(resData);
        } else {
          alert("Error uploading text");
          setIsLoading(false);
        }
      }
    } else if (dropdownVal !== null) {
      sendQuery();
    }
  }

  async function pollForDocumentStatus(resData) {
    // Send the document for review
    const documentId = resData["id"];
    console.log("documentId", documentId);
    // Sleep for 1 second
    await new Promise((r) => setTimeout(r, 1000));
    // Get the document status
    let [newResData, newStatus] = await getDocumentStatus(
      idToken,
      uploadKbId,
      documentId
    );
    console.log("newResData", newResData);

    let documentStatus = newResData["vectorization_status"];

    let pollCount = 0;
    while (documentStatus !== "COMPLETE" && pollCount < 120) {
      // Sleep for 1 second
      await new Promise((r) => setTimeout(r, 1000));
      [newResData, newStatus] = await getDocumentStatus(
        idToken,
        uploadKbId,
        documentId
      );
      console.log("newResData", newResData);

      let newDocumentStatus = newResData["vectorization_status"];
      documentStatus = newDocumentStatus;
      pollCount += 1;
    }

    if (documentStatus === "COMPLETE") {
      // Start the document review now
      console.log("Starting query");
      sendQuery();
    } else {
      alert("Error uploading text");
      setIsLoading(false);
    }
  }

  function onFileUploadButtonClick() {
    // `current` points to the mounted file input element
    inputFile.current.click();
  }

  function handleChange(files) {
    let fileList = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
    }
    const object = {
      target: {
        files: fileList,
      },
    };
    handleFileUpload(object);
  }

  // "File chats.txt already exists in knowledge base: 239aa451-cc85-447a-aa1d-4464d2f813a5."
  useEffect(() => {
    // Set the allFileUpload variable based on the fileData
    // fileData is an object with the following structure:
    // {
    //     name: "file content",
    // }

    for (const filename in fileData) {
      // Make sure this filename isn't already in the allUploadData array
      if (allUploadData.filter((file) => file.name === filename).length > 0) {
        continue;
      }
      setAllUploadData((prevState) => {
        return [
          {
            name: filename,
            type: "file",
            chunk_header: "",
            auto_context: true,
            document_title: "",
            link_to_source: "",
            supp_id: "",
            description: "",
          },
          ...prevState,
        ];
      });
    }
  }, [fileData]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"pdf search"} />
      <div className="advisor-assist-section">
        <div className="advisor-assist-container">
          <p className="compliance-review-header-text">PDF Search</p>
          <div style={{ display: "flex" }}>
            <div className="document-upload-container">
              <InfoIconWithText
                text={"Document"}
                helpBoxTitle={"Document"}
                helpBoxText={
                  "Select the document you want to use for this task."
                }
              />
              <CustomDropdown
                valueList={documents.map((doc) => doc.id)}
                textList={documents.map((doc) => doc.file_name)}
                value={dropdownVal}
                sendDataToParent={(val) => changeDropdownVal(val)}
                customStyle={{ width: "200px", maxHeight: "400px" }}
              />
            </div>

            {allUploadData.length === 0 && (
              <div className="document-upload-container">
                <p className="semi-bold-font-light">
                  Upload document to search
                </p>
                {allUploadData.length === 0 && (
                  <div
                    className={`alternate-upload-method-choice-light`}
                    onClick={onFileUploadButtonClick}
                    style={{ marginRight: "15px" }}
                  >
                    <IconContext.Provider
                      value={{
                        size: "18px",
                        color: "#383838",
                      }}
                    >
                      <MdOutlineFileUpload />
                    </IconContext.Provider>
                    <input
                      onChange={(event) => handleChange(event.target.files)}
                      type="file"
                      accept={fileTypes
                        .map((fileType) => "." + fileType)
                        .join(",")}
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                    <p className={`alternate-upload-method-text-light`}>
                      Browse Files
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div>
            {allUploadData.length > 0 && (
              <p className={`semi-bold-font-light`}>Document to search</p>
            )}
            {allUploadData.map((file, index) => (
              <div key={index} className="file-upload-container">
                <p className="regular-font-light">{file.name}</p>
                <div
                  style={{
                    marginLeft: "20px",
                    marginTop: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeFile(file.name)}
                >
                  <IconContext.Provider
                    value={{
                      size: "18px",
                      color: "#383838",
                    }}
                  >
                    <IoIosCloseCircle />
                  </IconContext.Provider>
                </div>
              </div>
            ))}
          </div>

          {allUploadData.length === 0 && (
            <p className="valid-file-types-text">
              {`Valid file types are: .txt, .pdf, .docx, .md, .mp3, .wav, .m4a, and .epub`}
            </p>
          )}

          {isLoading && <BouncingDots />}

          <div className="query-input-row">
            <div className="query-text-input-container">
              <CustomTextArea
                placeholderText={"Query"}
                text={queryText}
                sendDataToParent={(text) => setQueryText(text)}
                rows={4}
              />
            </div>
            <CustomButton
              buttonText="Query"
              disabled={
                queryText.length === 0 ||
                buttonDisabled ||
                (allUploadData.length === 0 && dropdownVal === null)
              }
              onClick={() => uploadDocumentToKnowledgeBase()}
            />
          </div>

          {(resultData.length > 0 || resultSummary != "") && (
            <div className="query-results-section">
              <p className={`header-font-light`}>Search results</p>
              <div className="query-result-options-row">
                {showSummary && (
                  <p
                    className={`result-type-text-light${
                      resultTypeShown == "summary" ? "-selected" : ""
                    }`}
                    onClick={() => setResultTypeShown("summary")}
                  >
                    Summary
                  </p>
                )}
                {showCitedResults && (
                  <p
                    className={`result-type-text-light${
                      resultTypeShown == "citedResults" ? "-selected" : ""
                    }`}
                    onClick={() => setResultTypeShown("citedResults")}
                  >
                    {`Cited results (${citedResults.length})`}
                  </p>
                )}
              </div>

              {resultTypeShown == "citedResults" &&
                citedResults.map((result, index) => (
                  <QueryResultsCard
                    theme={"light"}
                    key={index}
                    index={index}
                    result={result}
                  />
                ))}
              {resultTypeShown == "summary" &&
                summarizeResults &&
                resultSummary != null &&
                resultSummary != "" && (
                  <QueryResultSummaryCard
                    summary={resultSummary}
                    theme={"light"}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const QueryResultsCard = ({ result, index, theme }) => {
  return (
    <div className={`query-result-card-container-${theme}`}>
      <div className="query-result-card-header-row">
        <p
          className={`medium-font-${theme}`}
          style={{ fontSize: "16px", color: "var(--medium-gray)" }}
        >
          From -{" "}
        </p>
        <div className={`query-result-title-container-${theme}`}>
          <p className={`query-result-title-text-${theme}`}>
            {result.document.title}
          </p>
        </div>
      </div>
      <div>
        <p
          className={`regular-font-${theme}`}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {result.content}
        </p>
      </div>
    </div>
  );
};

const QueryResultSummaryCard = ({ summary, theme }) => {
  return (
    <div className={`query-result-card-container-${theme}`}>
      <p className={`regular-font-${theme}`}>{summary}</p>
    </div>
  );
};

export default AdvisorAssist;
