import { useState, useEffect } from "react";

import { formatEpochToDate } from '../utils/dateFormatting';
import * as documentReviewServices from '../services/documentReview';
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { setFinalReview, setReadingInstructions, setReviewInstructions } from "../features/document/documentSlice.ts";


export function getDocumentReviewParams(useCase) {


    const FIRNA_REP_REF_KB_ID = '49fb1557-dc03-49a7-9f31-cdaf059fb43b'    // FINRA Registered Representative Rules and Regulations (FINRA-only Compliance)
    const RIA_REF_KB_ID = '64be5a17-d2b0-4ca8-951a-eeced0a0b9d0'          // Registered Investment Advisor (or RIA) Rules and Regulations (SEC-only Compliance)
    const HYBRID_RIA_REF_KB_ID = '7df1f6b3-7f22-419e-b1bf-faaad7acbe1f'   // Hybrid Registered Investment Advisor (or Hybrid RIA) Rules and Regulations (FINRA and SEC Compliance)

    const RIA_REVIEW_ARGS = {
        'reference_knowledge_base_ids': [RIA_REF_KB_ID],
        'active_reading_instructions': 'This marketing text was written by a Registered Investment Advisor who must comply with SEC rules and regulations. Analyze the text and identify any potential issues or violations that may conflict with the SEC marketing compliance rules and regulations for a Registered Investment Advisor.',
        'final_review_instructions': 'Clearly list any statements or potential issues from the original text that are not compliant with SEC marketing rules and regulations for a Registered Investment Advisor. You must state what part of the text is not compliant with SEC regulations, why that part of the original text is not compliant with SEC regulations, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with SEC marketing regulations for a Registered Investment Advisor.',
        'auto_query_guidance': 'Look for SEC marketing rules and regulations applicable to a Registered Investment Advisor who must comply with SEC rules and regulations.'
    }

    // FINRA Registered Representative Rules and Regulations (FINRA-only Compliance)
    const FIRNA_REP_REVIEW_ARGS = {
        'reference_knowledge_base_ids': [FIRNA_REP_REF_KB_ID],
        'active_reading_instructions': 'This marketing text was written by a FINRA Registered Representative. Analyze the marketing document and identify any potential issues or violations that may conflict with the FINRA marketing compliance rules and regulations for a FINRA Registered Representative.',
        'final_review_instructions': 'Clearly list any statements or potential issues from the original text that are not compliant with FINRA marketing rules and regulations for a FINRA Registered Representative. You must state what part of the text is not compliant with FINRA rules and regulations, why the original text is not compliant with FINRA rules and regulations, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with FINRA marketing regulations for a FINRA Registered Representative.',
        'auto_query_guidance': 'Look for FINRA marketing rules and regulations applicable to a Registered Representative who must comply with FINRA rules and regulations.'
    }

    // Hybrid Registered Investment Advisor(or Hybrid RIA) Rules and Regulations(FINRA and SEC Compliance)
    const HYBRID_RIA_REVIEW_ARGS = {
        'reference_knowledge_base_ids': [HYBRID_RIA_REF_KB_ID],
        'active_reading_instructions': 'This marketing text was written by a Hybrid Registered Investment Advisor who must be in compliance with both FINRA and SEC rules and regulations. Analyze the text and identify any potential issues or violations that may conflict with either the SEC marketing compliance rules for a Hybrid Registered Investment Advisor or the FINRA marketing compliance rules for a Hybrid Registered Investment Advisor.',
        'final_review_instructions': 'Clearly list any potential issues from the original text that are not compliant with SEC marketing rules and regulations for a Hybrid Registered Investment Advisor or potential issues from the original text that are not compliant with the FINRA marketing rules and regulations for a Hybrid Registered Investment Advisor. You must state what part of the text is non-compliant with the SEC or FINRA rules and regulations, why the original text is not compliant with the SEC or FINRA rules and regulations, and suggest a solution for every flagged issue. If there are no issues, state why the document is in compliance with SEC marketing regulations and FINRA marketing regulations for a Hybrid Registered Investment Advisor.',
        'auto_query_guidance': 'Look for SEC and FINRA marketing rules and regulations applicable to a Hybrid Registered Investment Advisor who must comply with both SEC and FINRA rules and regulations.'
    }

    switch (useCase) {
        case "ria":
            return [
                RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                RIA_REVIEW_ARGS['active_reading_instructions'],
                RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "gpt-3.5-turbo",
                "gpt-3.5-turbo",
                RIA_REVIEW_ARGS['auto_query_guidance']
            ];
        case "finra":
            return [
                FIRNA_REP_REVIEW_ARGS['reference_knowledge_base_ids'],
                FIRNA_REP_REVIEW_ARGS['active_reading_instructions'],
                FIRNA_REP_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "gpt-3.5-turbo",
                "gpt-3.5-turbo",
                FIRNA_REP_REVIEW_ARGS['auto_query_guidance']
            ];

        case "hybrid":
            return [
                HYBRID_RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                HYBRID_RIA_REVIEW_ARGS['active_reading_instructions'],
                HYBRID_RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "gpt-3.5-turbo",
                "gpt-3.5-turbo",
                HYBRID_RIA_REVIEW_ARGS['auto_query_guidance']
            ];
        default:
            return [
                RIA_REVIEW_ARGS['reference_knowledge_base_ids'],
                RIA_REVIEW_ARGS['active_reading_instructions'],
                RIA_REVIEW_ARGS['final_review_instructions'],
                "medium",
                "gpt-3.5-turbo",
                "gpt-3.5-turbo",
                RIA_REVIEW_ARGS['auto_query_guidance']
            ];
    }

}


export function formatPreviousReviews(previousReviews) {

    // First sort the reviews by created_on timestamp
    previousReviews.sort((a, b) => (a.created_on > b.created_on) ? -1 : 1)

    // First format the timestamp from epoch to a human-readable format
    let formattedReviews = []
    previousReviews.forEach((review) => {
        let timestamp = review['created_on'];
        let formattedDate = formatEpochToDate(timestamp, true);
        const title = review["response"]["title"];
        if (title === undefined || title === null || title === "") {
            // Just ignore this
            return;
        }
        formattedReviews.push({
            id: review['id'],
            date: formattedDate,
            status: review['status'],
            request: review["request"],
            response: review["response"]
        })
    })

    console.log("formattedReviews", formattedReviews)

    return formattedReviews;
}


export function usePreviousReviewsHandler(idToken, username) {

    // Get the api key and secret from session storage
    let data = sessionStorage.getItem('data');
    if (data === undefined || data === null) {
        data = null;
    } else {
        data = JSON.parse(data);
    }

    const kbID = "239aa451-cc85-447a-aa1d-4464d2f813a5"

    // const idToken = data["idToken"]
    // const username = sessionStorage.getItem('username');

    const [isLoading, setIsLoading] = useState(true);
    const [previousReviews, setPreviousReviews] = useState([]);

    let nextPageToken = null;

    async function getDocumentContent(documentId) {
        setIsLoading(true);
        const [resData, status] = await documentReviewServices.getDocument(idToken, kbID, documentId);
        console.log("resData", resData)
        setIsLoading(false);
        return [resData, status];
    }

    async function getPreviousReviews() {
        setIsLoading(true);
        const [resData, status] = await documentReviewServices.requestPreviousReviews(idToken, nextPageToken, username);
        if (status === 200) {
            const formattedReviews = formatPreviousReviews(resData['document_review_jobs']);
            setPreviousReviews(formattedReviews);
        } else {
            alert("Sorry, there was an error getting your previous reviews. Please try again later.")
        }
        
        setIsLoading(false);
    }

    useEffect(() => {
        getPreviousReviews()
    }, [])

    return { previousReviews, isLoading, getDocumentContent }

}


export function useDocumentReviewHandler(setIsLoading) {
    const dispatch = useAppDispatch();

    const [progress, setProgress] = useState(0);
    const [resultData, setResultData] = useState(null);
    const [showProgress, setShowProgress] = useState(false);

    // const username = sessionStorage.getItem('username');
    const username = useAppSelector(state => state.auth.user._id);


    async function runDocumentAnalysis(
        idToken, knowledgeBaseId, documentId, readingInstructions, reviewInstructions, referenceKnowledgeBaseIds = []
    ) {

        // Reset these
        setIsLoading(true);
        setResultData(null);
        setProgress(0);

        const segmentLength = "medium";
        const readingModel = "gpt-3.5-turbo";
        const reviewModel = "claude-3-opus";
        const autoQueryGuidance = "";

        const [resData, status] = await documentReviewServices.reviewDocument(
            idToken, knowledgeBaseId, documentId, referenceKnowledgeBaseIds, readingInstructions,
            reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance, ""
        )

        console.log("resData", resData)

        const pollURL = resData.id;
        let pollCount = 0;
        if (status === 200 || status === 202) {
            // Poll for a completed status

            let documentReviewStatus = "PENDING"
            while (pollCount < 900 && documentReviewStatus !== "COMPLETE") {

                const [pollResData, pollStatus] = await documentReviewServices.pollDocumentReviewStatus(idToken, pollURL);
                console.log("pollResData", pollResData)

                if (pollStatus === 200 || pollStatus === 202) {
                    setProgress(pollResData.response.active_reading_progress_pct * 100);
                }

                // Sleep for 1 second
                await new Promise(r => setTimeout(r, 1000));
                pollCount += 1;
                documentReviewStatus = pollResData["status"];

                if (documentReviewStatus === "FAILED") {
                    alert("Sorry, this document review failed. Please try again later or contact support.")
                    setShowProgress(false);
                    setIsLoading(false);
                    break;
                }

                if (documentReviewStatus === "COMPLETE") {
                    console.log("pollResData.response.final_review", pollResData.response.final_review)
                    setResultData(pollResData.response.final_review);
                    setShowProgress(false);
                    setIsLoading(false);

                    console.log("Setting readingInstructions", readingInstructions)
                
                    dispatch(
                        setReadingInstructions(readingInstructions)
                    );
                    dispatch(
                        setReviewInstructions(reviewInstructions)
                    );
                    dispatch(
                        setFinalReview(pollResData.response.final_review)
                    );

                }

            }

            if (documentReviewStatus !== "COMPLETE" && documentReviewStatus !== "FAILED") {
                alert("Sorry, this document review is taking longer than expected. Please try again later.")
            }

        } else {
            setShowProgress(false);
        }

    }

    async function reviewDocument(
        knowledgeBaseId, documentId, useCase, idToken, authToken
    ) {

        const [
            referenceKnowledgeBaseIds, readingInstructions, reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance
        ] = getDocumentReviewParams(useCase);

        let formattedDocumentId = documentId;
        if (documentId == "all") {
   
            formattedDocumentId = null;
        }

        // Reset these
        setIsLoading(true);
        setResultData(null);
        setProgress(0);

        const [resData, status] = await documentReviewServices.reviewDocument(
            idToken, knowledgeBaseId, formattedDocumentId, referenceKnowledgeBaseIds, readingInstructions,
            reviewInstructions, segmentLength, readingModel, reviewModel, autoQueryGuidance, username
        )

        console.log("resData 123", resData)

        let pollCount = 0;
        if (status === 202) {
            // Poll for a completed status

            let documentReviewStatus = "PENDING"
            while (pollCount < 900 && documentReviewStatus !== "COMPLETE") {

                const [pollResData, pollStatus] = await documentReviewServices.pollDocumentReviewStatus(idToken, resData.id);
                console.log("pollResData", pollResData)

                if (pollStatus === 200) {
                    setProgress(pollResData.response.active_reading_progress_pct * 100);
                }

                // Sleep for 1 second
                await new Promise(r => setTimeout(r, 1000));
                pollCount += 1;
                documentReviewStatus = pollResData["status"];

                if (documentReviewStatus === "FAILED") {
                    alert("Sorry, this document review failed. Please try again later or contact support.")
                    setShowProgress(false);
                    setIsLoading(false);
                    break;
                }

                if (documentReviewStatus === "COMPLETE") {
                    console.log("pollResData.response.final_review", pollResData.response.final_review)
                    setResultData(pollResData.response.final_review);
                    setShowProgress(false);
                    setIsLoading(false);
                }

            }

            if (documentReviewStatus !== "COMPLETE" && documentReviewStatus !== "FAILED") {
                alert("Sorry, this document review is taking longer than expected. Please try again later.")
            }

        } else {
            setShowProgress(false);
        }

    }

    return { reviewDocument, runDocumentAnalysis, progress, showProgress, resultData, setResultData }

}

